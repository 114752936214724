import Header from '@/components/common/layout/header/Header'
import IncidentSearchLayout from '@/components/pages/people/incident-search/Layout'
import IncidentSearchMain from '@/components/pages/people/incident-search/Main'

const IncidentSearchPage = () => {
	return (
		<IncidentSearchLayout
			header={
				<Header className='flex h-14 items-center px-1'>
					<div className='text-xl'>ติดตาม/ค้นหาสถานะใบแจ้ง</div>
				</Header>
			}
		>
			<IncidentSearchMain />
		</IncidentSearchLayout>
	)
}

export default IncidentSearchPage
